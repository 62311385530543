import { SERVICE_TYPES } from "../enums/serviceTypes";

export const isSecondType = (type) => {
  if (
    type === SERVICE_TYPES.Flyttstädning ||
    type === SERVICE_TYPES.Storstädning
  ) {
    return true;
  }
  return false;
};
