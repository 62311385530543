export const STEP2 = {
  Hemstädning: {
    heading: "Vilket startmöte passar dig bäst?",
    description:
      "Välj om du vill ha ditt startmöte som ett personligt hembesök eller ett digitalt hembesök. ",
    homeBtn:
      "För en tydlig bild av din bostad ses vi hemma hos dig. Tillsammans går vi igenom dina behov och önskemål för att få en löpande hemstädning som passar dig. ",
    digitalBtn:
      "Här ses vi i ett digitalt videosamtal där du visar oss ditt hem och vi tillsammans går igenom dina behov och önskemål. Länken till videosamtalet skickas till din e-post.",
  },
  Flyttstädning: {
    heading: "Något du vill lägga till?",
    description:
      "Utöver en komplett flyttstädning med 2-sidig fönsterputs så kan du välja att lägga till extra moment som vi gärna hjälper till med. ",
  },
  Storstädning: {
    heading: "Något du vill lägga till?",
    description:
      "Utöver en komplett storstädning så kan du välja att lägga till extra moment som vi gärna hjälper till med. ",
  },
  Kontorsstädning: {
    heading: "Vilket startmöte passar dig bäst?",
    description:
      "Välj om du vill ha ditt startmöte som ett personligt kontorsbesök eller ett digitalt kontorsbesök. ",
    homeBtn:
      "För en tydlig bild av ert kontor ses vi hemma hos dig. Tillsammans går vi igenom era behov och önskemål för att få en löpande kontorsstädning som passar er.  ",
    digitalBtn:
      "Här ses vi i ett digitalt videosamtal där du visar oss ert kontor och vi tillsammans går igenom era behov och önskemål. Länken till videosamtalet skickas till din e-post.",
  },
};

export const STEP2_BTNS = {
  "Nej, tack!": {
    title: "Nej, tack!",
    description: "Skippa detta steget",
  },
  "Inglasad balkong/ uterum": {
    title: "Inglasad balkong/uterum",
    description: "in- och utvändig fönsterputs",
  },
  "2-sidig fönsterputs": {
    title: "2-sidig fönsterputs",
    description: "in- och utvändigt",
  },
  "4-sidig fönsterputs": {
    title: "4-sidig fönsterputs",
    description: "in- och utvändigt samt mellan",
  },
  "Extra ugnsplåtar": {
    title: "Extra ugnsplåtar",
    description: "utöver en som ingår",
  },
};

export const STEP3 = {
  Hemstädning: {
    heading: "Välj tid för startmöte",
  },
  Flyttstädning: {
    heading: "Välj önskat datum för flyttstädning",
    description:
      "Bokningsförfrågan är inte bindande utan ger oss möjlighet att titta i vårt schema för att se om datum finns ledigt. Vi återkopplar så snart vi kan!",
  },
  Storstädning: {
    heading: "Välj önskat datum för storstädning",
    description:
      "Bokningsförfrågan är inte bindande utan ger oss möjlighet att titta i vårt schema för att se om datum finns ledigt. Vi återkopplar så snart vi kan!",
  },
  Kontorsstädning: {
    heading: "Välj tid för startmöte",
  },
};
