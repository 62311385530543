import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import "./OpenDropdown.scss";

const OpenDropdown = ({ onClick }) => {
  return (
    <div className="OpenDropdown" onClick={onClick} aria-hidden="true">
      <AiOutlineInfoCircle />
      <h2>Klicka här för att se vad som ingår</h2>
    </div>
  );
};

export default OpenDropdown;
