import React from "react";
import "./FormInput.scss";

const FormInput = (props) => {
  return (
    <div className="FormInput">
      {props.textarea ? (
        <textarea
          value={props.value}
          onChange={(e) => props.onChange && props.onChange(e.target.value)}
          // placeholder={props.placeholder}
          placeholder="ss"
        />
      ) : (
        <input
          value={props.value}
          onChange={(e) => props.onChange && props.onChange(e.target.value)}
          // placeholder={props.placeholder}
          placeholder="ss"
          type={props.type}
          required
        />
      )}
      <span className="placeholder">{props.placeholder}</span>
    </div>
  );
};

export default FormInput;
