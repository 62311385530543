import React from "react";
import { SERVICE_TYPES } from "../../../enums/serviceTypes";
import TicketDropdownFlyt from "../TicketDropdown/TicketDropdownFlyt";
import TicketDropdownStors from "../TicketDropdown/TicketDropdownStors";
import { GrClose } from "react-icons/gr";
import "./Step2Popup.scss";

const Step2Popup = ({ choosenService, onClose, active }) => {
  return (
    <div className={"Step2Popup" + (active ? " active" : "")}>
      <div className="popup-content">
        {choosenService === SERVICE_TYPES.Flyttstädning ? (
          <TicketDropdownFlyt />
        ) : (
          <TicketDropdownStors />
        )}
        <button className="close" onClick={onClose}>
          <GrClose />
        </button>
      </div>
    </div>
  );
};

export default Step2Popup;
