import React from "react";
import "./CalculatingPrice.scss";
import loader from "../../../assets/images/loader.png";
// import { BiLoaderAlt } from "react-icons/bi";

const CalculatingPrice = () => {
  return (
    <div className="CalculatingPrice">
      <h2>Beräknar ditt pris</h2>
      <div className="loader">
        <img src={loader} alt="Loader" />
      </div>
    </div>
  );
};

export default CalculatingPrice;
