import React from "react";
import "./TicketDropdowns.scss";
import Dropdown from "./Dropdown/Dropdown";

const TicketDropdownFlyt = () => {
  return (
    <div className="TicketDropdown">
      <h2>Detta ingår i städningen</h2>
      <Dropdown
        text="Allmänt / alla utrymmen"
        items={[
          "Putsar fönster.",
          "Dammsuger och våttorkar snickerier, lister, dörrar, dörrkarmar, fönsterbrädor, fria ytor, garderobsdörrar och skåpdörrar.",
          "Torkar ovanpå och invändigt i garderober, skåp, och bokhyllor.",
          "Dammsuger och våttorkar element.",
          "Avlägsnar fläckar från strömbrytare.",
          "Dammtorkar eluttag.",
          "Dammtorkar väggar.",
          "Dammtorkar lampor.",
          "Putsar speglar.",
          "Dammsuger och moppar golv.",
        ]}
      />
      <Dropdown
        text="Kök (inkl. allmänna moment)"
        items={[
          "Rengör kyl och frys ut- och invändigt.",
          "Rengör spis och ugn ut- och invändigt.",
          "Rengör ugnsgaller och ugnsplåt (1st ingår).",
          "Rengör fläkt och fläktfilter.",
          "Rengör ut- och invändigt samt ovanpå skåp, hyllor och lådor.",
          "Rengör kakel/stänkskydd ovanför diskbänk.",
          "Rengör mikrovågsugn ut- och invändigt.",
          "Putsar diskho, blandare, propp och sil.",
          "Rengör köksbänk.",
        ]}
      />
      <Dropdown
        text="Badrum (inkl. allmänna moment)"
        items={[
          "Rengör väggar och golv.",
          "Avfettar och avkalkar väggar där det är möjligt.",
          "Rengör hela duschen alt. badkaret.",
          "Rengör golvbrunn.",
          "Putsar blandare och duschmunstycke.",
          "Rengör synliga rör.",
          "Torkar ut- och invändigt samt ovanpå badrumsskåp.",
          "Rengör utvändigt vitvaror.",
          "Rengör kran och handfat.",
          "Rengör ut- och invändigt toalettstolen.",
        ]}
      />
      <Dropdown
        text="Att tänka på inför städningen"
        items={[
          "Stäng av och frosta av kyl och frys.",
          "Dra ut kyl, frys, spis och tvättmaskin från väggen om du vill att vi rengör bakom.",
          "Skruva upp fönster som kräver det vid beställning av 4-sidig fönsterputs.",
          "Montera av eventuell badkarsfront för att vi ska komma åt under och rengöra.",
          "Informera oss gärna om du har känsliga material, t.ex. marmor eller mässing i din bostad.",
        ]}
      />
    </div>
  );
};

export default TicketDropdownFlyt;
